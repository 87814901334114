/** file: /components/backOffice/orders/orders.css **/

th,
td {
	text-align: center;
}
h1 {
	z-index: 11;
}
.monthSelect {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	font-size: 1.5em;
	font-weight: bold;
	z-index: 11;
}
.changeDate {
	font-size: 2em;
	cursor: pointer;
	transition: all 0.3s ease;
}
.changeDate:hover {
	fill: var(--orange);
}
.paidStatus {
	cursor: pointer;
}
.orderSearch {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	font-weight: bold;
	z-index: 9;
}
.orderSearch button {
	border: none;
	display: flex;
	align-items: center;
	background: none;
	font-size: 2em;
	margin: auto;
	transition: all 0.2s ease;
}
