/** file: /components/backOffice/amaps/amaps.css **/

table,
tr {
	border-collapse: collapse;
}

th,
td {
	border: 1px solid;
}
th,
td {
	padding: 0 0.5em;
}

.rowEnd {
	border: none;
	font-size: 1.5rem;
	background: none;
}
.rowEnd svg:not(.action svg) {
	margin: auto;
}
.action {
	cursor: pointer;
	transition: all 0.3s;
}
.action:hover {
	color: var(--black);
	background: none;
}
.action {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.action p {
	padding: 0;
	margin: 0;
	font-size: 1rem;
}
th .action {
	border: 1px solid;
	border-radius: 5px;
	padding: 0.3em;
}
th .action:hover {
	background-color: whitesmoke;
	color: var(--black);
}
