/** file: /components/backOffice/layout.css **/
nav {
	width: 100vw;
	background-color: var(--lemon);
	display: flex;
	align-items: center;
}
nav .lemonText {
	display: none;
}
.logoLink {
	cursor: pointer;
	display: flex;
	align-items: center;
	position: relative;
}
.orange {
	transition: all 300ms ease;
}
nav svg {
	max-height: 45px;
	margin: auto;
}
nav ul {
	width: 100%;
	height: 60px;
	margin: 0;
	list-style: none;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding-left: 0;
}
nav li {
	width: 100%;
	text-align: center;
}
nav a {
	text-decoration: none;
	text-transform: uppercase;
	color: var(--black);
	position: relative;
	transition: all 300ms ease;
}
nav h3,
nav p {
	margin: 0;
}

nav p {
	color: var(--black);
	margin-right: auto;
}
nav a:not(.logoLink a)::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	display: block;
	height: 3px;
	width: 100%;
	background: var(--black);
	border-radius: 10px;
	transform: scaleX(0);
	transform-origin: 0%;
	transition: all 300ms ease;
}
nav a:hover {
	color: var(--sanguine);
}
nav a:not(.logoLink a):hover::after {
	transform: scaleX(1);
	background: var(--sanguine);
}
.active a {
	color: var(--sanguine);
}
.active a::after {
	transform: scaleX(1);
	background: var(--sanguine);
}
.logoLink a {
	margin: auto;
}
.logoLink a:hover path.orange {
	fill: var(--sanguine);
}
.disconnect {
	cursor: pointer;
	display: flex;
	color: var(--black);
	font-size: 1.5em;
	padding: 1em;
}
.disconnect:hover {
	color: var(--sanguine);
}
