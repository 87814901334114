/** file: /components/backOffice/home.css **/

.topSection {
	align-items: center;
}
.go {
	color: var(--dark-green);
}
.stop {
	color: var(--strawberry);
}
.recap {
	max-width: '1100px';
	margin: 0 auto;
}
.recapMonthSelect {
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 1.5em;
	font-weight: bold;
	z-index: 11;
	max-width: 500px;
	margin: 0 auto;
}
.recap h1 {
	margin-bottom: 0;
}
.recap h2 {
	margin: 0 auto;
}
.dateInput {
	align-items: center;
	margin: 0 auto;
}
.dateInput label {
	padding-right: 2em;
}
.dateInput input {
	margin: 0;
}
.rowEnd.recapRowEnd {
	font-size: unset;
}
.recapRowEnd .button {
	font-size: 2em;
	display: flex;
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	margin: 2px 0;
	border: none;
}
.disable {
	background-color: lightgray;
	color: var(--black);
	border: 1px solid lightgray;
}
