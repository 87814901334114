/** file: /components/login.css **/

.login {
	flex-direction: column;
	margin: auto;
	min-width: 330px;
}

.login h1 {
	text-align: center;
}

.logo {
	position: relative;
	width: 200px;
	height: auto;
	margin: auto;
}

.field {
	width: 100%;
	position: relative;
	border-bottom: 1px dashed;
	margin: 4rem auto 1rem;
	transition: 300ms;
}

.label {
	font-size: 1.2rem;
}

.input {
	outline: none;
	border: none;
	overflow: hidden;
	margin: 0;
	width: 100%;
	padding: 0.25rem 0;
	font-size: 1.2rem;
	background: transparent;
	transition: border 500ms;
}

.input:valid {
	color: var(--dark-green);
}
.input:invalid {
	color: var(--strawberry);
}

.field::after {
	content: '';
	position: relative;
	display: block;
	height: 4px;
	width: 100%;
	background: var(--orange);
	transform: scaleX(0);
	transform-origin: 0%;
	opacity: 0;
	transition: all 300ms ease;
	top: 2px;
}

.field:focus-within {
	border-color: transparent;
}
.field:focus-within::after {
	transform: scaleX(1);
	opacity: 1;
}

.label {
	position: absolute;
	transform: translateY(-2rem);
	transform-origin: 0%;
	transition: transform 400ms;
	pointer-events: none;
}

.field:focus-within .label,
.input:not(:placeholder-shown) + .label {
	transform: scale(0.8) translateY(-5rem);
}

.forgotPassword {
	width: 100%;
	text-align: right;
	font-size: 0.8em;
	margin-top: -1em;
	font-weight: lighter;
	cursor: pointer;
}

.button {
	cursor: pointer;
	background-color: var(--orange);
	color: var(--black);
	border: 1px solid var(--orange);
	margin: 2rem auto;
	transition: all 200ms ease;
}

.button:hover {
	color: var(--orange);
	background-color: var(--black);
}

.noBorder {
	border: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.noBorder:focus-within .label,
.noBorder .input:not(:placeholder-shown) + .label {
	transform: none;
}
.noBorder .label {
	position: unset;
	transform: none;
}
.noBorder::after {
	display: none;
}
