/** file: /components/backOffice/users/users.css **/

.searchForm {
	position: relative;
}
.userSearch {
	width: 250px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
	z-index: 9;
}
.userSearch button {
	border: none;
	display: flex;
	align-items: center;
	background: none;
	font-size: 2em;
	margin: auto;
	transition: all 0.2s ease;
}
.searchButton {
	color: var(--dark-green);
}
.resetSearch {
	position: absolute;
	right: 0;
	padding-left: 0;
	padding-right: 0;
	color: var(--black);
	cursor: pointer;
}

.resetSearch:hover {
	color: var(--strawberry);
}
