/** file: /components/backOffice/products/editProducts.css **/

.product form {
	display: flex;
	height: unset;
	flex-direction: row;
	gap: 3em;
	overflow: unset;
	margin: auto;
	max-width: unset;
}
.available svg {
	fill: var(--dark-green);
}
.unavailable svg {
	fill: var(--strawberry);
}
