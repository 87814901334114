/** file: /components/toaster.css **/

.toastContainer {
	width: 100%;
	position: absolute;
	top: -4em;
	justify-content: center;
}
.login .toastContainer {
	top: 2em;
	left: 0;
}
.toast {
	padding: 1em 3em;
	border-radius: 1em;
	cursor: pointer;
	text-align: center;
	z-index: 21;
	position: fixed;
	top: 1em;
	box-shadow: 1px 15px 13px var(--black);
}
.toast h1 {
	font-size: 3em;
	margin: 0;
}
.toast p {
	margin: 0;
}
.error {
	background: var(--strawberry);
}
.success {
	background: var(--light-green);
	color: var(--black);
}
.warning {
	background: var(--orange);
	color: var(--black);
}
