.tablesContainer {
	display: grid;
	grid-template-columns: repeat(4, 280px);
	column-gap: 1px;
	max-width: 1120px;
	margin: auto;
	font-size: 1.2em;
}
.etiquette {
	margin-bottom: 1px;
	width: 100%;
}
.etiquette th,
.etiquette td {
	padding: 3px;
}