/** file: /index.css **/

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;700&display=swap');

:root {
	--black: #232323;
	--white: #fffeca;
	--dark-green: #6b7c00;
	--light-green: #bdbc3e;
	--lemon: #fcc531;
	--orange: #f8b605;
	--strawberry: #e8293f;
	--sanguine: #9c031f;
}

html,
body {
	margin: 0;
	padding: 0;
	font-family: 'Red Hat Display', sans-serif;
}
body {
	padding: 0 1em;
	padding-bottom: 2em;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}
hr {
	width: 100%;
	height: 1px;
	border: 0.5px solid;
	color: var(--lemon);
}
.printHeader {
	display: none;
}
progress {
	-webkit-appearance: none;
	appearance: none;
}
progress[value]::-webkit-progress-bar {
	background-color: whitesmoke;
	border-radius: 10px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
progress[value]::-webkit-progress-value {
	background-color: var(--dark-green);

	border-radius: 10px;
	background-size: 35px 20px, 100% 100%, 100% 100%;
}
::-webkit-progress-value {
	transition: all 0.3s ease;
}

@media print {
	nav,
	.orderSearch,
	.monthSelect,
	.rowEnd,
	.notPaid,
	.webHeader,
	.amapOrderColumn,
	.noPrint {
		display: none !important;
	}
	body {
		background-color: white;
		color: black;
		font-size: 12px;
	}
	a {
		text-decoration: none;
		color: black;
	}
	.printHeader {
		display: unset;
	}
	.pageBreak {
		page-break-before: always;
	}
}
