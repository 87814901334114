/** file: /App.css **/

h1,
h2 {
	text-align: center;
	margin: 1em auto;
}
a {
	color: var(--sanguine);
}
.orange {
	fill: var(--orange);
}
.lemon {
	fill: var(--white);
}
.circle {
	stroke: var(--light-green);
	stroke-width: 60px;
}

.main {
	min-height: 90vh;
}
.flex {
	display: flex;
}
.column {
	flex-direction: column;
}

.container {
	flex-grow: 1;
	justify-content: flex-start;
	position: relative;
}

label {
	display: block;
}

input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	box-sizing: border-box;
	border-radius: 2px;
	border: 1px solid;
}

.modal {
	position: fixed;
	left: 0;
	top: 63px;
	width: 100vw;
	height: 100vh;
	padding: 0 20%;
	background: #ffffff;
	padding-bottom: 70px;
	z-index: 12;
}

.danger {
	background-color: var(--strawberry);
	color: whitesmoke;
}

.delete {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 5px;
	transition: all 0.3s ease;
}
.delete:hover {
	background-color: whitesmoke;
	color: var(--strawberry);
}

.mainActions {
	height: 100px;
	justify-content: end;
}

.bigCheckbox {
	width: 25px;
	height: 25px;
	margin: auto;
	display: block;
}
