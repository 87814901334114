/** file: /components/backOffice/amaps/editAmaps.css **/

.modal form {
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	max-width: 500px;
	margin: auto;
	outline: 1px solid;
	padding: 1em;
	border-radius: 10px;
}

.modal .field {
	margin: 2rem auto 1rem;
}
